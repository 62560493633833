import React, { useState, useEffect } from "react";
import emailLogo from "../../assets/emailLogo.png";
import fbLogo from "../../assets/fbLogo.png";
import igLogo from "../../assets/igLogo.png";
import inLogo from "../../assets/linkedInLogo.png";
import locLogo from "../../assets/locationLogo.png";
import phoneLogo from "../../assets/phoneLogo.png";
import twitterLogo from "../../assets/twitterLogo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Contact = () => {
  // Toast notification
  const successNotify = () => {
    toast.success("Message was successful", { autoClose: 3000 });
  }
  const errorNotify = () => {
    toast.error("Message was unsuccessful", { autoClose: 3000 });
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  // detecting if dark mode is enabled
  useEffect(() => {
    // const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const htmlElement = document.documentElement;

    // const handleChange = () => {
    //   setIsDarkMode(mediaQuery.matches);
    // };

    const handleClassChange = (mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          setIsDarkMode(htmlElement.classList.contains("dark"));
        }
      }
    };

    // also not sure if making any difference
    // mediaQuery.addEventListener("change", handleChange);
    // setIsDarkMode(mediaQuery.matches);

    const observer = new MutationObserver(handleClassChange);
    observer.observe(htmlElement, { attributes: true });

    return () => {
      // mediaQuery.removeEventListener("change", handleChange);
      observer.disconnect();
    };
  }, []);

  // sending the user contact info to the server
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      name,
      email,
      message,
    };
    try {
      const response = axios
        .post("https://iykyknow-homepage-dev-backend.azurewebsites.net/api/home/contactEmail", formData)
        .then((response) => {
          console.log("Server Response:", response.data, response.status);
          successNotify();

        })
        .catch((error) => {

          errorNotify();
          if (error.response){
            console.error();
          }
        });
    } catch (error) {
      console.error();
    }
  };

  return (
    <div className="dark:bg-[#1E1E1E] pb-20" id="contactUs">
      {isDarkMode ? (
        <ToastContainer
          toastStyle={{ backgroundColor: "#3b3b3b", color: "#fff" }}
        />
      ) : (
        <ToastContainer />
      )}

      <div
        className="relative grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:w-11/12 w-[310px] p-8 
        border border-gray-200 rounded-3xl sm:p-6 md:p-10 mx-auto md:mt-56 mt-24 shadow-[0_3px_30px_-5px_rgba(0,0,0,0.3)] 
        md:pb-40  2xl:w-2/3 dark:bg-[#3b3b3b] dark:border-none pb-5 "
      >
        <form className="space-y-6 md:p-4" onSubmit={handleSubmit}>
          <div className="block mb-5">
            <h4 className="text-3xl font-semibold mb-2  ">Get in touch</h4>
            <p className="text-sm font-medium text-gray-900  dark:text-[#CCCCCC]">
              We are here for you! How can we help?
            </p>
          </div>
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-[#CCCCCC]"
            >
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300  text-sm rounded-lg 
              focus:ring-customGreen 
            focus:border-customGreen block w-full md:w-1/2 p-2.5 dark:bg-[#585858]"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900  dark:text-[#CCCCCC]"
            >
              Email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300  text-sm rounded-lg
             block w-full md:w-1/2 p-2.5 dark:bg-[#585858] "
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900  dark:text-[#CCCCCC]"
            >
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              id="message"
              cols={30}
              rows={5}
              className="border border-gray-300 rounded-lg w-full md:w-1/2 bg-gray-50 dark:bg-[#585858] p-2"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full md:w-1/2 text-white bg-customGreen hover:bg-customHover focus:ring-4 focus:outline-none focus:ring-customGreen
            font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:font-bold"
          >
            Submit
          </button>
        </form>
        <div className="hidden md:block space-y-4 mt-6 md:mt-0 absolute bottom-0 right-0 mb-48 mr-64 md:mr-24 xl:mr-64">
          <div className="flex items-center">
            <div className="mr-2">
              <a href="/">
                <img src={locLogo} alt="location pin Logo" className=" " />
              </a>
            </div>
            <label
              htmlFor="locationLogo"
              className="text-gray-500 dark:text-[#CCCCCC]"
            >
              Manhattan
            </label>
          </div>

          <div className="flex items-center">
            <div className="mr-2">
              <a href="/">
                <img src={phoneLogo} alt="phone logo" className=" " />
              </a>
            </div>
            <label
              htmlFor="phoneLogo"
              className="text-gray-500  dark:text-[#CCCCCC]"
            >
              +1 234 567 8900
            </label>
          </div>

          <div className="flex items-center">
            <div className="mr-2">
              <a href="/">
                <img src={emailLogo} alt="email logo" className=" " />
              </a>
            </div>
            <label
              htmlFor="emailLogo"
              className="text-gray-500  dark:text-[#CCCCCC]"
            >
              iykyk-support@iykyknow.ai
            </label>
          </div>
        </div>
        <div
          className="hidden absolute bottom-0 right-0 border bg-[#a7a7a7] dark:bg-[#585858] p-2 rounded-tl-2xl rounded-bl-2xl 
        shadow-[0_3px_30px_0px_rgba(0,0,0,0.3)] dark:border-none md:mb-10"
        >
          <img src={fbLogo} alt="" className=" " />
          <img src={igLogo} alt="" className=" " />
          <img src={inLogo} alt="" className=" " />
          <img src={twitterLogo} alt="" className=" " />
        </div>
      </div>
    </div>
  );
};

export default Contact;