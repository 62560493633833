import React from 'react'
import Header from "./Header";
import Product from "./Product";
import About from "./About";
import Project from "./Project";
import Contact from "./Contact";

const Home = () => {
  return (
    <div>
      <Header />
      <Product />
      <About />
      <Project />
      <Contact />
    </div>
  )
}

export default Home