import React from "react";
import userLogo from "../../assets/Profile.png";
import paperLogo from "../../assets/Paper.png";
import chatLogo from "../../assets/Chat.png";
import vectorLight from "../../assets/Vectorlight.png";

const Product = () => {
  return (
    <div className="flex flex-col items-center relative md:mt-16 mt-10 space-y-6" id="products">
      <div className="flex flex-col items-center relative md:mt-28 md:mb-10 md:mx-16 mx-4 lg:mx-32 " >
        <div className="flex flex-col px-4 md:px-0">
          <h2 className="text-4xl font-bold lg:mx-auto  dark:text-[#CCCCCC]">
            Our Products
          </h2>
          <hr className="h-[3px] bg-black border-10 w-14 rounded-lg mt-10 md:mt-5 md:ml-1 mb-5 lg:mx-auto " />
          <p className="w-full text-gray-500  dark:text-[#CCCCCC]">
            IYKYK provides a diverse range of cutting-edge products, spanning
            from advanced social media management tools and efficient invoice
            readers to intelligent chat bots, catering to various aspects of
            digital engagement and business optimization.
          </p>
        </div>
      </div>
      <div className="md:grid md:grid-cols-3 md:gap-1 md:ml-0.5 lg:gap-5">
        <div
          className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80   md:px-3 p-6 bg-white 
          shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg pt-6 px-10  dark:bg-[#3b3b3b] mb-2 md:mb-0"
        >
          <img src={userLogo} alt="userLogo" className="h-20 md:h-14  " />
          <h5 className="mb-2 text-2xl  dark:text-[#CCCCCC] font-bold tracking-tight text-gray-900 mt-8 ">
            Sociasphere
          </h5>
          <p className=" text-gray-500 font-thin  dark:text-[#CCCCCC] dark:font-normal">
            An AI-Driven social media management tool. Our Sentiment Analysis
            software allows you insight into understanding your customers and
            identifying trends. AI-Generated captions provided with simple input
            of a photo and description. Newsletters and blog posts created with
            minimal effort.
          </p>
        </div>
        <div
          className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80  md:px-3 p-6 bg-white 
          shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg px-10  dark:bg-[#3b3b3b] mb-2 md:mb-0"
        >
          <img src={chatLogo} alt="userLogo" className="h-20 md:h-14  " />
          <h5 className="mb-2 text-2xl   font-bold tracking-tight text-gray-900 mt-8 dark:text-[#CCCCCC]">
            ChatBot
          </h5>
          <p className=" text-gray-500 font-thin dark:text-[#CCCCCC] dark:font-normal">
            Redefine how you connect with your audience with our AI-powered
            chatbot application. Seamlessly blending cutting-edge artificial
            intelligence with intuitive conversation, our chatbot is your
            virtual assistant, ready to engage users, answer queries, and
            provide personalized assistance.
          </p>
        </div>
        <div
          className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80   md:px-3 p-6 bg-white 
          shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg px-10  dark:bg-[#3b3b3b]"
        >
          <img src={paperLogo} alt="userLogo" className="h-20 md:h-14  " />
          <h5 className="mb-2 text-2xl   font-bold tracking-tight text-gray-900 mt-8 dark:text-[#CCCCCC]">
            Invoice Reader
          </h5>
          <p className=" text-gray-500 font-thin dark:text-[#CCCCCC] dark:font-normal">
            Experience the future of invoicing with our AI-powered Invoice App.
            Say goodbye to manual data entry and tedious invoice processing.
            Transform your invoicing process into a seamless, intelligent, and
            time-saving experience, allowing you to focus on what matters most,
            growing your business.
          </p>
        </div>
      </div>

      {/* <button
        className="border-customGreen border-solid border-[1.5px] rounded-3xl md:w-44 h-12 hover:ring-2 hover:ring-customGreen
        text-customGreen mt-5 md:mt-10 w-1/3 dark:bg-customGreen dark:text-white dark:font-bold"
      >
        Learn more
      </button> */}
      <img
        src={vectorLight}
        alt="Vectorlight"
        className="absolute inset-0 lg:w-[40rem] z-[-10] ml-0"
      />
    </div>
  );
};

export default Product;
