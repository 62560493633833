import React from "react";

const Header = () => {
  return (
    <div className="flex flex-col mx-4 md:mx-16 lg:mx-32 md:space-y-32 space-y-32 dark:bg-[#1E1E1E] md:mt-48">
      <div className="space-y-6">
        <h1 className="text-5xl font-bold sm:text-6xl">
          Leaders in AI innovative <br />
          technology.
        </h1>
        <div className="space-y-12">
          <p className="">
            IYKYK leverages artificial intelligence to develop innovative
            <br />
            applications that empower individuals and businesses by enhancing
            <br />
            efficiency, productivity, and overall user experience.
          </p>
          <div className="md:block flex flex-col md:items-center">
            {/* <button
              type="button"
              className="text-white bg-customGreen hover:bg-customHover 
           font-normal rounded-3xl text-sm 
          px-5 py-2.5 me-2 mb-2 lg:w-1/3 md:w-1/3 max-w-56 dark:font-bold"
            >
              Learn more
            </button> */}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Header;
