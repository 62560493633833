import React from "react";
import cartLogo from "../../assets/Buy.png";
import camLogo from "../../assets/Camera.png";
import eyeLogo from "../../assets/Show.png";
import vector from "../../assets/Vector.png";

const Project = () => {
  return (
    <div>
      <img
        src={vector}
        alt="vectorlogo"
        className="hidden absolute md:w-[500px] z-[-10] mt-[25rem] right-0 "
      />

      <div className="flex flex-col relative md:mt-56 mt-32 " id="projects">
        <h2 className="text-4xl font-bold md:ml-6 ml-8 lg:mx-auto  ">
          Check out our newest projects
        </h2>
        <hr className="h-[3px] bg-black border-10 w-14 rounded-lg mt-10 md:mt-5 mb-5 ml-9 md:ml-7 lg:mx-auto  " />
        <div className="flex flex-col items-center relative mt-10">
          <div className="md:grid md:grid-cols-3 md:gap-1 md:ml-0.5 lg:gap-5">
            <div
              className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80 md:px-3 p-6 bg-white 
            shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg pt-6 px-10 dark:bg-[#3b3b3b] mb-2 md:mb-0"
            >
              <img src={camLogo} alt="cameraLogo" className="h-20 md:h-14  " />
              <h5 className="mb-2 text-2xl  font-bold tracking-tight text-gray-900 mt-8 dark:text-[#CCCCCC]">
                Flavor Frame
              </h5>
              <p className="text-gray-500 font-thin dark:text-[#CCCCCC] dark:font-normal">
                Snap a picture of any food-related subject with your phone's
                camera and our AI app will produce a stunning, high quality
                studio-style image of the same food item.
              </p>
              {/* <a
                href="/"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-customGreen mt-4 dark:font-bold"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a> */}
            </div>

            <div
              className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80   md:px-3 p-6
             bg-white shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg px-10 dark:bg-[#3b3b3b] mb-2 md:mb-0"
            >
              <img src={cartLogo} alt="cartLogo" className="h-20 md:h-14 " />
              <h5 className="mb-2 text-2xl  font-bold tracking-tight text-gray-900 mt-8 dark:text-[#CCCCCC]">
                Wholesale App
              </h5>
              <p className="text-gray-500 font-thin  dark:text-[#CCCCCC] dark:font-normal">
                An AI Chatbot that specializes in reading databases. Ask
                questions of the database, such as “How many stores are there?”.
                “What is the top selling item for store 77?”
              </p>
              {/* <a
                href="/"
                className="inline-flex items-center px-3 py-2 text-sm   font-medium text-center text-customGreen mt-4 dark:font-bold"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a> */}
            </div>

            <div
              className="block md:col-span-1 w-[310px] md:w-[250px] lg:w-80 md:px-3 p-6
             bg-white shadow-[0_3px_30px_-10px_rgba(0,0,0,0.3)] rounded-lg px-10 dark:bg-[#3b3b3b]"
            >
              <img src={eyeLogo} alt="eyeLogo" className="h-20 md:h-14 " />
              <h5 className="text-2xl   font-bold tracking-tight text-gray-900 mt-5 mb-1 dark:text-[#CCCCCC]">
                Price Comparison App
              </h5>
              <p className="text-gray-500 font-thin  dark:text-[#CCCCCC] dark:font-normal">
                Experience the future of invoicing with our AI-powered Invoice
                App. Say goodbye to manual data entry and tedious invoice
                processing. Transform your invoicing process into a seamless,
                intelligent, and time-saving experience, allowing you to focus
                on what matters most, growing your business.
              </p>
              {/* <a
                href="/"
                className="inline-flex items-center px-3 py-2  text-sm font-medium text-center text-customGreen dark:font-bold"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a> */}
            </div>
          </div>
          {/* <button
            className="border-customGreen border-solid border-[1.5px] rounded-3xl md:w-44 h-12 text-customGreen
           mt-5 md:mt-10 w-1/3 dark:bg-customGreen dark:font-bold dark:text-white hover:ring-2 hover:ring-customGreen"
          >
            View all
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Project;
