import React, { useState, useEffect } from 'react';
import logo from '../assets/IYKYK_Final_File.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  let [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  let [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    let locDark = !isDarkMode;
    localStorage.setItem("dark-mode", locDark.toString());
  };

  // initial load checks if system defaults to dark mode if it does then we autoset darkmode
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    if (localStorage.getItem("dark-mode") && localStorage.getItem("dark-mode") === 'true'){
      setIsDarkMode(mediaQuery.matches);
    }
    else {
      setIsDarkMode(false);
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <nav className=" dark:bg-[#1E1E1E]">
      <div className="md:max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
        <a href="/" className="flex items-center rtl:space-x-reverse">
          <img src={logo} className="md:h-[7rem] h-[5rem] " alt="IYKYK Logo" />
        </a>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden 
    hover:bg-gray-100 dark:hover:bg-[#383838] focus:outline-none focus:ring-2  focus:ring-gray-200 dark:focus:ring-[#585858]"
          aria-controls="navbar-default"
          aria-expanded={isMobileMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isMobileMenuOpen ? '' : 'hidden'
          } w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul
            className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 
      md:border-0 dark:bg-[#1E1E1E] text-gray-900 dark:text-[#CCCCCC]"
          >
            {/* sun icon */}
            {/* className={`${isDarkMode ? '' : 'hidden'}`} */}
            <button
              onClick={toggleTheme}
              className={`${isDarkMode ? 'hidden' : ''} mb-5 ml-1`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 3v2.25m6.364.386-1.591 1.591M21 
                  12h-2.25m-.386 6.364-1.591-1.591M12 
                  18.75V21m-4.773-4.227-1.591 1.591M5.25 
                  12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 
                  3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                />
              </svg>
            </button>
            {/* moon icon */}
            <button
              onClick={toggleTheme}
              className={`${!isDarkMode ? 'hidden' : ''} mb-5 ml-1`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                />
              </svg>
            </button>
            <li>
              <a
                href="/"
                className="block py-2 px-3 bg-customGreen rounded md:bg-transparent md:text-customGreen md:p-0"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <Link
                to="/Product#products"
                className="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-customGreen md:p-0 dark:hover:bg-[#383838]"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="/About#about"
                className="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-customGreen md:p-0 dark:hover:bg-[#383838]"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/Project#projects"
                className="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-customGreen md:p-0 dark:hover:bg-[#383838]"
              >
                Discover
              </Link>
            </li>
            <li>
              <Link
                to="/Contact#contactUs"
                className="block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-customGreen md:p-0 dark:hover:bg-[#383838]"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;