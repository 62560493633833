import React from "react";
import laptopLogo from "../../assets/132.png";

const About = () => {
  return (
    <div
      className="flex md:mt-48 mx-4 sm:mx-8 md:mx-5 md:space-x-2 lg:space-x-20 mt-24 
      xl:space-x-28 " id="about"
    >
      <img
        src={laptopLogo}
        alt="laptopLogo"
        className="hidden md:inline-block md:h-[250px] md:w-[400px] lg:w-[500px] xl:w-[650px] 
          xl:h-[350px]   mt-10 rounded"
      />

      <div className="flex flex-col px-4 lg:px-0">
        <h2 className="text-4xl font-bold  ">About IYKYK</h2>
        <hr className="h-[3px] bg-black border-10 w-14 rounded-lg mt-10 md:mt-5 mb-5  " />
        <p className="w-full text-gray-900 mb-7 md:mb-3 dark:text-[#CCCCCC] dark:font-normal">
          We started small.
        </p>
        <p className="w-full lg:w-11/12 text-gray-500 dark:text-[#CCCCCC] dark:font-normal ">
          At IYKYK, our mission is more than just a technological journey; it’s
          a human endeavor. We passionately believe in the transformative power
          of AI to enhance and elevate human experiences. We are dedicated to
          integrating AI solutions into businesses, not just as tools but as
          collaborators. Our commitment is to free up valuable human hours,
          allowing businesses and their employees to focus on innovation,
          creativity, and meaningful human interactions.
        </p>
        <div className="md:block flex flex-col items-center">
          {/* <button
            className="border-customGreen border-solid border-[1.5px] rounded-3xl md:w-44 h-12 hover:ring-2 hover:ring-customGreen 
        text-customGreen mt-5 md:mt-10 w-2/3  dark:text-white dark:bg-customGreen dark:font-bold"
          >
            Learn more
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default About;
