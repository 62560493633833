import React from 'react';
import './styles/App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home/HomePage';
import ScrollToAnchor from './components/ScrollToAnchor';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="dark:bg-[#1E1E1E] dark:text-[#CCCCCC] pt-5 md:pt-4 ">
      <Router>
          <ScrollToAnchor />
          <Navbar />
        <Routes>
          <Route path='/Project' element={<Home />} />
          <Route path='/Product' element={<Home />} />
          <Route path='/About' element={<Home />} />
          <Route path='/Contact' element={<Home />} />
          <Route path='/' element={<Home />}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
